// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-collaborate-js": () => import("./../src/pages/collaborate.js" /* webpackChunkName: "component---src-pages-collaborate-js" */),
  "component---src-pages-conference-2024-jsx": () => import("./../src/pages/conference-2024.jsx" /* webpackChunkName: "component---src-pages-conference-2024-jsx" */),
  "component---src-pages-conference-summit-2022-jsx": () => import("./../src/pages/conference-summit-2022.jsx" /* webpackChunkName: "component---src-pages-conference-summit-2022-jsx" */),
  "component---src-pages-conference-summit-2023-jsx": () => import("./../src/pages/conference-summit-2023.jsx" /* webpackChunkName: "component---src-pages-conference-summit-2023-jsx" */),
  "component---src-pages-data-research-jsx": () => import("./../src/pages/data-research.jsx" /* webpackChunkName: "component---src-pages-data-research-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-action-4-inclusion-jsx": () => import("./../src/pages/projects/action4inclusion.jsx" /* webpackChunkName: "component---src-pages-projects-action-4-inclusion-jsx" */),
  "component---src-pages-projects-data-analytics-js": () => import("./../src/pages/projects/data-analytics.js" /* webpackChunkName: "component---src-pages-projects-data-analytics-js" */),
  "component---src-pages-projects-dear-presidents-js": () => import("./../src/pages/projects/dear-presidents.js" /* webpackChunkName: "component---src-pages-projects-dear-presidents-js" */),
  "component---src-pages-projects-everyday-justice-js": () => import("./../src/pages/projects/everyday-justice.js" /* webpackChunkName: "component---src-pages-projects-everyday-justice-js" */),
  "component---src-pages-projects-jsx": () => import("./../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-projects-m-plan-jsx": () => import("./../src/pages/projects/m-plan.jsx" /* webpackChunkName: "component---src-pages-projects-m-plan-jsx" */),
  "component---src-pages-projects-scopra-jsx": () => import("./../src/pages/projects/scopra.jsx" /* webpackChunkName: "component---src-pages-projects-scopra-jsx" */),
  "component---src-pages-projects-social-justice-cafe-jsx": () => import("./../src/pages/projects/social-justice-cafe.jsx" /* webpackChunkName: "component---src-pages-projects-social-justice-cafe-jsx" */),
  "component---src-pages-summit-2020-jsx": () => import("./../src/pages/summit-2020.jsx" /* webpackChunkName: "component---src-pages-summit-2020-jsx" */),
  "component---src-pages-summit-2024-jsx": () => import("./../src/pages/summit-2024.jsx" /* webpackChunkName: "component---src-pages-summit-2024-jsx" */),
  "component---src-pages-summit-conference-2021-jsx": () => import("./../src/pages/summit-conference-2021.jsx" /* webpackChunkName: "component---src-pages-summit-conference-2021-jsx" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-posts-jsx": () => import("./../src/templates/posts.jsx" /* webpackChunkName: "component---src-templates-posts-jsx" */),
  "component---src-templates-topic-jsx": () => import("./../src/templates/topic.jsx" /* webpackChunkName: "component---src-templates-topic-jsx" */)
}

